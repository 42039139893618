import React, { useState, useEffect, useContext } from 'react'
import './style.scss'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

import Intro from '../../../animations/Intro'
import Vimeo from '../../Video/HTML'

import NaviContext from '../../../context/NaviContext'
import validatePdf from '../../../utils/validatePdf'

function HeroCarousel() {

  const navi = useContext(NaviContext)

  const data = useStaticQuery(graphql`
    query Hero {
      contentfulSuperHero {
        hero {
          name
          firstLine
          secondLine
          link
          imagevideo {
            title
            description
            gatsbyImageData
            file {
              url
            }
          }
          imagevideoMobile {
            title
            description
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
  `)


  // Delay Synchronization	
  const [baseDelay, setBaseDelay] = useState(1200)

  const [slideNr, setSlideNr] = useState(0)
  const [uiHover, setUiHover] = useState(false)

  const slideRate = 12000

  let timer = null

  const heros = data?.contentfulSuperHero?.hero?.map((item, i) => {
    const content = item?.imagevideo?.gatsbyImageData ? (
      <GatsbyImage
        image={item?.imagevideo?.gatsbyImageData}
        imgStyle={{ objectFit: 'cover' }}
        objectPosition='50% 50%'
        title={item?.imagevideo?.title}
        alt={item?.imagevideo?.description}
      />
    ) : item?.imagevideo?.file?.url ?
      <Vimeo file={item.imagevideo?.file?.url} hideVolumeButton={true} alwaysMuted={true} title={item.imagevideo?.title} description={item.imagevideo?.description}/>
      :
      null

    if (slideNr === i)
      return (
        <div key={`slide-${i}`} className="slide">
          <div className="pic border-left-off">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay} className="c5 border-c5 t innerborder">
              <div className="img placeholder">
                {item.link ?
                  <a className='hero-link nostrike' href={validatePdf(item.link)} target="_blank" aria-label="Hero link">
                    {content}
                  </a>
                  :
                  content
                }
              </div>
            </Intro>
          </div>
          <div className="aside border-left-off">
            {item?.firstLine ?
              <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 700} className="c0 border-c5 margin-bottom--1 t">
                <h1>{item?.firstLine}</h1>
              </Intro>
              : null}
            {item?.secondLine ?
              <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 700 + 250} className="c0 border-c5  t">
                <h1>{item?.secondLine}</h1>
              </Intro>
              : null}
          </div>
        </div>
      )
  })

  const herosMobile = data?.contentfulSuperHero?.hero?.map((item, i) => {
    const content = item?.imagevideoMobile?.gatsbyImageData ? (
      <GatsbyImage
        image={item?.imagevideoMobile?.gatsbyImageData}
        imgStyle={{ objectFit: 'cover' }}
        objectPosition='50% 50%'
        title={item?.imagevideo?.title}
        alt={item?.imagevideo?.description}
      />
    ) : item?.imagevideoMobile?.file?.url ?
      <Vimeo file={item.imagevideoMobile?.file?.url} hideVolumeButton={true} alwaysMuted={true} title={item.imagevideo?.title} description={item.imagevideo?.description}/>
      :
      null

    if (slideNr === i)
      return (
        <div key={`slide-${i}`} className="slide">
          <div className="pic border-left-off">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay} className="c5 border-c5 t innerborder">
              <div className="img placeholder">
                {item.link ?
                  <a className='hero-link nostrike' href={validatePdf(item.link)} target="_blank" aria-label="Hero link">
                    {content}
                  </a>
                  :
                  content
                }
              </div>
            </Intro>
          </div>
          <div className="aside border-left-off">
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 700} className="c0 border-c5 margin-bottom--1 t">
              <h1>{item?.firstLine}</h1>
            </Intro>
            <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 700 + 250} className="c0 border-c5  t">
              <h1>{item?.secondLine}</h1>
            </Intro>
          </div>
        </div>
      )
  })

  let max = heros.length

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const heroCarouselSwipeElement = document.getElementById(`hero-carousel`)
      let touchstartX = 0
      let touchendX = 0
          
      function checkDirection() {
        if (touchendX < touchstartX && touchstartX - touchendX > 100 ) {setSlideNr((nr) => {setBaseDelay(0); return nr + 1 < max ? nr + 1 : 0}); return true}
        if (touchendX > touchstartX && touchendX - touchstartX > 100) {setSlideNr((nr) => {setBaseDelay(0); return nr - 1 >= 0 ? nr - 1 : max - 1}); return true}
        else return false
      }

      heroCarouselSwipeElement.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX
      })

      heroCarouselSwipeElement.addEventListener('touchend', e => {
        const currentSliderPic = heroCarouselSwipeElement.querySelector(`.animation-intro-wrap .slide .pic`)
        touchendX = e.changedTouches[0].screenX
        const touchDistance = touchendX - touchstartX;
        const moveDistance = Math.abs(touchDistance) > 10 ? 10 : Math.abs(touchDistance)
        const direction = touchDistance > 0 ? 1 : -1
        const translateDistance = direction * moveDistance
        currentSliderPic.style.transition = 'transform 0.3s ease, opacity 0.3s ease'

        const slideChanged = checkDirection()

        if(slideChanged) {
          currentSliderPic.style.transform = `translateX(${direction * 100}%)`
          currentSliderPic.style.opacity = 0
        }
        else {
          currentSliderPic.style.opacity = 1
          currentSliderPic.style.transform = `translateX(${translateDistance}px)`
          currentSliderPic.style.transform = 'none'
        }

      })

      heroCarouselSwipeElement.addEventListener('touchmove', e => {
        const currentSliderPic = heroCarouselSwipeElement.querySelector(`.animation-intro-wrap .slide .pic`)
        const touchmoveX = e.changedTouches[0].screenX
        const touchDistance = touchmoveX - touchstartX
        // const moveDistance = Math.abs(touchDistance) > 300 ? 300 : Math.abs(touchDistance)
        const moveDistance = Math.abs(touchDistance)
        const direction = touchDistance > 0 ? 1 : -1
        const translateDistance = direction * moveDistance
        currentSliderPic.style.transform = `translateX(${translateDistance}px)`
        currentSliderPic.style.opacity = Math.max(Math.exp(-0.01 * moveDistance), 0.1)
    })
    }
  },[max])

  // Slider animation
  useEffect(() => {

    let i = slideNr

    // Hover pause
    if (uiHover === true) {
      clearTimeout(timer)
    } else {
      timer = setTimeout(() => {
        i++
        if (max === i) {
          i = 0
        }
        setSlideNr(function () {
          setBaseDelay(0)
          return i
        })
      }, slideRate)
    }

    // Clear timeout on useEffect kill
    return () => {
      clearTimeout(timer)
    }

  }, [slideNr, uiHover, timer])

  // Keeping track of height of header and viewport so that we can keep the hero around viewport
  const [slideHeight, setSlideHeight] = useState(0)
  const textSpace = '(2 * (1.025em + 4vw)) + 2em'
  useEffect(() => {
    let vh = window.innerHeight
    // Checking window size, dropping values into state
    function updateSize() {
      if (window.innerHeight - 100 > vh || window.innerHeight + 100 < vh) {
        vh = window.innerHeight
      }
      const header = document?.getElementById('master-header-mimic')
      const carousel = document?.getElementById('master-carousel')
      const height = navi.onMobile ? 'calc(' + (vh - header?.offsetHeight - carousel.offsetHeight) + 'px - ' + textSpace + ')' : 'calc(' + (vh - header?.offsetHeight) + 'px - ' + textSpace + ')'
      setSlideHeight(height)
    }
    window.addEventListener('resize', updateSize)
    updateSize()

    // Kill off listener
    return () => window.removeEventListener('resize', updateSize)
  }, [navi.onMobile])

  // Clicking the dots
  function dotClicked(i) {
    setSlideNr(function () {
      setBaseDelay(0)
      return i
    })
  }

  // UI Dots
  const dots = heros.map((node, i) => {
    return (
      <Intro key={`dots-${i}`} visible={true} in={{ blink: 0 }} delayIn={baseDelay + 1500} className="c3 t"><div className={`${i === slideNr ? 'current' : ''} li`} role="button" aria-label='Change Slide' onClick={() => dotClicked(i)} onKeyDown={() => dotClicked(i)}></div></Intro>
    )
  })

  return (
    <div id="hero-carousel" className="" aria-label="Carousel picker" role="button" tabIndex={0} onMouseEnter={() => setUiHover(true)} onMouseLeave={() => setUiHover(false)} style={{ height: slideHeight, paddingBottom: 'calc(' + textSpace + ')' }}>
      
      <Intro switch={slideNr} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="">
        {!navi.onMobile ? heros : herosMobile}
      </Intro>

        <div className="dots">
          <div>
            {dots}
          </div>
        </div>
 
    </div>
  )
}

export default HeroCarousel
