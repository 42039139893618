import React, { useState } from 'react'
import './style.scss'
//import NaviContext from '../../../context/NaviContext'


import ExpertPicks from './ExpertPicks'
import Tv from './TV'

import Handle from './Handle'
import Content from './Content'
import BarMisc from './BarMisc'
import Bet from './1STBet'
import Wagering from './Wagering'
import { navigate } from 'gatsby-link'

function Sidebar(props) {
  //const naviContext = useContext(NaviContext)

  const bars = [
    {
      title: 'Live Racing',
      icon: 'wagering',
      content: <Wagering />,
      open: false,
    },
    {
      title: 'Expert Picks',
      icon: 'expert-picks',
      content: <ExpertPicks />,
      open: false,
    },
    {
      title: '1/ST Stream',
      icon: 'tv',
      content: <Tv />,
      open: false,
    },
    {
      title: 'Book My Event',
      icon: 'events',
      content: '/book-my-event/',
      open: false,
    },
    {
      title: 'Pegasus World Cup',
      icon: 'pegasus',
      content: 'https://www.pegasusworldcup.com/',
      open: false,
    },
    {
      title: 'Florida Derby',
      icon: 'derby',
      content: 'https://am.ticketmaster.com/gulfstreampark/ism/MjRHUDAzMzA=?_ga=2.190579752.2065482138.1709532278-436597739.1709530811',
      open: false,
    }
  ]


  // Delay Synchronization	
  const baseDelay = 1200
  const stepDelay = 250

  // Keep and change accordion open status
  const [accordion, setAccordion] = useState(bars.map(node => node.open))
  function handleClick(i, content) {
    if (content === '/book-my-event/')
      navigate(content)
    if (content === 'https://www.pegasusworldcup.com/' && typeof window !== 'undefined')
      window.open(content, "_blank");
    if (content === 'https://am.ticketmaster.com/gulfstreampark/ism/MjRHUDAzMzA=?_ga=2.190579752.2065482138.1709532278-436597739.1709530811' && typeof window !== 'undefined')
      window.open(content, "_blank");
    else
      setAccordion(prev => {
        let current = !prev[i]
        let final = prev.map(() => false)
        final[i] = current
        return final
      })
  }

  const barsMap = bars.map(function (node, i) {

    const delay = baseDelay + stepDelay * i
    return (
      <div key={`bar-${i}`} className={`accordion-wrapper bar ${i > 0 ? 'border-top-off' : ''}`}>
        <Handle icon={node.icon} title={node.title} delay={delay} asideBox={true} onClickPassage={() => handleClick(i, node.content)} />
        {accordion[i] && typeof (node.content) !== 'string' ?
          <Content delay={delay}>
            {node.content}
          </Content>
          : null}
      </div>
    )
  })

  return (
    <div id="sidebar">

      <div id="master-sidebar" className="">

        {barsMap}

      </div>

      <Bet />

      <BarMisc />

    </div>
  )
}

export default Sidebar