import React from 'react'
import './style.scss'

import Intro from '../../../../animations/Intro'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

function List(props) {

  // Delay Synchronization	
  // const [baseDelay, setBaseDelay] = useState(0)
  const baseDelay = 0
  const stepDelay = 50

  const data = useStaticQuery(graphql`query SideBarExperts {
  allContentfulPerson(
    limit: 40
    filter: {type: {eq: "Expert"}}
    sort: {fields: order}
  ) {
    nodes {
      name
      featured
      order
      image {
        gatsbyImageData(
          quality: 70
          layout: CONSTRAINED
          breakpoints: [300, 500, 750, 1080, 1366, 1920]
          formats: WEBP
        )
        title
        description
      }
    }
  }
}`)
  // let helper = 2
  const expertsMap = data?.allContentfulPerson?.nodes?.map((expert, i) => {
    if (expert.name?.toLowerCase() === 'dean keppler')
      return (
        <a target='_blank' className={`${expert.featured ? '' : 'hidden'}`} key={`expert-${i}`} href={`https://inthemoneypodcast.com/category/blog/santa-anita/`}>
          <div className="item bar hover-trigger">
            <div className="aside">
              <Intro visible={true} in={{ border: 300, bg: 300, fade: 300 }} delayIn={baseDelay + stepDelay * i} className="decor c5 border-c5 border-top-off t">
                <div className="img placeholder layer">
                  <div className="aspect-ratio">
                    <GatsbyImage
                      image={expert?.image?.gatsbyImageData}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      title={expert?.image?.title}
                      alt={expert?.image?.description}
                      className="bg img-cover"
                    />
                  </div>
                </div>
              </Intro>
            </div>
            <div className="main">
              <div className="row">
                <div className="bg-hover"></div>
                <Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={baseDelay + stepDelay * i} className="box c0 t">
                  <h4 className="active-dip">{expert.name}</h4>
                </Intro>
              </div>
            </div>
          </div>
        </a>
      )
    else
      return (
        <Link className={`${expert.featured ? '' : 'hidden'}`} key={`expert-${i}`} to={`${expert.name === 'Jeff Siegel' ? '/wagering/#handicapping-with' : '/wagering#picks?expert=' + expert.name.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')} `}>
          <div className="item bar hover-trigger">
            <div className="aside">
              <Intro visible={true} in={{ border: 300, bg: 300, fade: 300 }} delayIn={baseDelay + stepDelay * i} className="decor c5 border-c5 border-top-off t">
                <div className="img placeholder layer">
                  <div className="aspect-ratio">
                    <GatsbyImage
                      image={expert?.image?.gatsbyImageData}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      title={expert?.image?.title}
                      alt={expert?.image?.description}
                      className="bg img-cover"
                    />
                  </div>
                </div>
              </Intro>
            </div>
            <div className="main">
              <div className="row">
                <div className="bg-hover"></div>
                <Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={baseDelay + stepDelay * i} className="box c0 t">
                  <h4 className="active-dip">{expert.name}</h4>
                </Intro>
              </div>
            </div>
          </div>
        </Link>
      )
  })



  return (
    <div className="list">
      {expertsMap}
      <Link key={`experts-more`} to={`/wagering/#expert-picks`}>
        <div className="item bar hover-trigger">
          <div className="main">
            <div className="row">
              <div className="bg-hover"></div>
              <Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={baseDelay + stepDelay * (expertsMap.length + 1)} className="box c0 t  last-item">
                <h4 className="active-dip">More Experts</h4>
              </Intro>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default List
