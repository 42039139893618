import React, {useEffect, memo} from 'react'
import './style.scss'
import './gulfstream-style.scss'

import BlocksRouter from './BlocksRouter'

function Sections(props) {
	// const {data, pageContext} = props
	const {data} = props
	//const [classes, setClasses] = useState()

	const sectionsContentful = data.contentfulPage?.sections

	const sections = sectionsContentful

	let classes = null
	
	const sectionsMap = sections?.map((node, i) => {
		if (i === 0) {
			classes = node.classes
		}
		return (
			<section id={`section-${i}`} key={`basic-${i}`} className={`basic ${node.classes}`}>
				{node.anchor ? <div id={node.anchor} className="anchor"></div> : null}
				<BlocksRouter {...node} />
			</section>
		)
	})

	useEffect(() => {
		const mimic = document.getElementById('master-header-mimic')
    if(mimic) {
      mimic.classList = ''
      mimic.className += classes
    }

	},[classes])
	
  return sectionsMap ? sectionsMap : false
}

export default memo(Sections)