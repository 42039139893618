import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'

function Handle(props) {
	
  return (
		<div className="accordion-content-wrapper">
			<Intro visible={true} in={{ border: 500, fade: 500 }} delayIn={0} className="c0 border-c5 border-right-off t">
				{props.children}
			</Intro>
		</div>
	)
}

export default Handle