import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import { useStaticQuery, graphql } from "gatsby"

function BarMisc() {

  const data = useStaticQuery(graphql`
  query Social{
    contentfulOptions {
      social {
        key
        value
      }
    }
  }
`)

  // Delay Synchronization	
  const baseDelay = 1200
  const stepDelay = 150

  const linksMap = data?.contentfulOptions?.social?.map((link, i) => {
    return (
      <Intro key={i} visible={true} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * (i + 1)} className="uppercase">
        <a href={link.value} target="_blank" rel="noreferrer noopener">{link.key}</a>
      </Intro>
    )
  })

  return (
    <div id="bar-misc" className="">

      <div className="links x0 t">
        {linksMap}
      </div>

      <div className="newsletter">
        <Intro visible={true} in={{ border: 500, bg: 500, blink: 500 }} delayIn={baseDelay} className="c3 t border-right-off">
          <a href="#modal-1st-subscribe" className='nostrike'><h5>Subscribe</h5></a>
        </Intro>
      </div>

    </div>
  )
}

export default BarMisc
