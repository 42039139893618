import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import { useStaticQuery, graphql } from "gatsby"

function Bet() {

  const data = useStaticQuery(graphql`
  query Bet{
    contentfulOptions {
      betDescription {
        data
      }
    }
  }
`)
  // console.log(data)
  // Delay Synchronization	
  const baseDelay = 1200
  const stepDelay = 150

  const desc = data?.contentfulOptions?.betDescription?.data

  return (
    <div id="bar-bet" className="">
      <a href="https://1st.com/bet/gulfstream-park?utm_source=gsp&utm_medium=track_referral&utm_campaign=1st_bet_racing_track_sponsors&utm_content=1st_bet_racing_track_sponsors_gsp_logo" target="_blank" rel="noreferrer noopener" className='nostrike anchor-wrap'>
        <div className="bars">
          <Intro visible={true} in={{ bg: 1500 }} delayIn={baseDelay + stepDelay * 3} className="c3 t bar red"></Intro>
          <Intro visible={true} in={{ bg: 1750 }} delayIn={baseDelay + stepDelay * 1} className="c3 t bar white"></Intro>
          <Intro visible={true} in={{ bg: 1050 }} delayIn={baseDelay + stepDelay * 4} className="c3 t bar blue"></Intro>
          <Intro visible={true} in={{ bg: 1350 }} delayIn={baseDelay + stepDelay * 2} className="c3 t bar yellow"></Intro>
          <Intro visible={true} in={{ bg: 2000 }} delayIn={baseDelay + stepDelay * 5} className="c3 t bar green"></Intro>
        </div>

        <div className="text">
          <h4 className='h2'>
            <Intro visible={true} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 3} className="">
              <span className="first-line">1/ST</span>
            </Intro>
            <Intro visible={true} in={{ blink: 500, draw: 1500 }} delayIn={baseDelay + stepDelay * 4} className="with-svg">
              <span className="second-line">BET</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="183.216" height="69.385" viewBox="0 0 183.216 69.385">
                <path id="Path_2439" data-name="Path 2439" d="M53.586,67.78Q78.262,70.512,125,63.941c25.249-4.6,55.447-19.684,57.26-33.058C188.044-11.783,28.247-5.235,1.78,20.346-.2,22.264.007,26.3.007,29.171c0,7.985,10.519,14.9,14.716,17.086C58.171,68.849,149.3,77.186,181.054,47.989" transform="translate(182.819 68.982) rotate(180)" fill="none" stroke="#000" strokeWidth="0.8" />
              </svg>
            </Intro>
          </h4>
        </div>

        <div className="feature">
          <Intro visible={true} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 7} className="">
            <h4 className='h5'><span style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: desc }} /></h4>
          </Intro>
        </div>
      </a>
    </div>
  )
}

export default Bet
