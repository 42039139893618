import React, { useState, useEffect } from 'react'
import './style.scss'
import use1stAPI from '../../../Api'

// import Intro from '../../../animations/Intro'
// import HTMLVideo from '../../Video/HTML'
// import Noise from '../../Canvas/Noise'
// import { useAuth0 } from '../../../utils/auth'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBacon } from '@fortawesome/free-solid-svg-icons'

function Tv() {

	// const { isAuthenticated, loginWithRedirect, user } = useAuth0()
	
	// function handleLoginClick() {
	// 	if (!isAuthenticated) {
	// 	  loginWithRedirect({
	// 			appState: {
	// 				 returnTo: window.location.pathname
	// 			}
	// 		})
	// 	}
	// }
	// FED FROM API but base params
	// const todayDate = new Date()
	// const todayYear = todayDate.getFullYear()
	// const todayMonth = todayDate.getMonth() + 1
	// const todayMonthLeadingZero = todayMonth < 10 ? '0' + todayMonth : '' + todayMonth
	// const todayDay = todayDate.getDate()
	// const todayDayLeadingZero = todayDay < 10 ? '0' + todayDay : '' + todayDay
	// const todayRaceDate = `${todayYear}-${todayMonthLeadingZero}-${todayDayLeadingZero}`
	// const [currentRace, setCurrentRace] = useState({
	// 	race_date: todayRaceDate,
	// 	race_number: null,
	// 	highest_race: 1,
	// 	track_code: 'GP',
	// 	event_code: 'GPM',
	// 	mtp: null,
	// 	feed: "https://videos.ctfassets.net/71tzd15sgbj0/3qEvNX63sH1EyGPx91W62k/e5b06a69bd54f4ad6068539c2548072c/drone_racing_loop__720p_.mp4"
	// })

	// const {data: live, loading: liveLoading = true} = use1stAPI('live', currentRace)

	// Track url changes
	// useEffect(() => {
	// 	if(live?.url && new URL(live?.url).origin !== new URL(currentRace.feed).origin) 
	// 		setCurrentRace((old) => {return {...old, feed: live.url}})
	// },[live?.url, currentRace?.feed])

	// const videoSource = isAuthenticated && user && !liveLoading ? currentRace?.feed : false

  return (
		<div className="tv-box c5">
			<div className={`aspect-ratio wysiwyg-iframe`}  role="button" aria-label="Iframe" tabIndex={0}>
			{/* <div className={`aspect-ratio wysiwyg-iframe ${!isAuthenticated ? 'hover-trigger' : ''}`} onClick={() => !isAuthenticated ? handleLoginClick() : null} onKeyDown={() => !isAuthenticated ? handleLoginClick() : null} role="button" tabIndex={0}> */}
				<iframe src={"https://livestream.com/accounts/9869799/events/3519786/player?width=640&amp;height=360&amp;enableInfoAndActivity=true&amp;defaultDrawer=&amp;autoPlay=true&amp;mute=false"} frameBorder="0" allowFullScreen="allowfullscreen" title="Stream"></iframe>
				{/* {videoSource ? 
					<iframe src={videoSource} title="Stream"></iframe>
				: liveLoading ?
					<div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBacon} /></div></div>
				:
					<HTMLVideo hideVolumeButton={true} file={'https://videos.ctfassets.net/71tzd15sgbj0/3qEvNX63sH1EyGPx91W62k/e5b06a69bd54f4ad6068539c2548072c/drone_racing_loop__720p_.mp4'}/>
				} */}
			</div>
			{/* <Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={500} className="x0 border-c5 border-right-off border-bottom-off t overlay-box">
				<h6>Next Race: 05/23/2021</h6>
			</Intro> */}
		</div>
  )
}

export default Tv
