import React from 'react'
import './style.scss'

// import Intro from '../../../animations/Intro'
import List from './List'

function ExpertPicks(props) {

    return (
			<div className="expert-picks-box">
				<div id="sidebar-expert-picks">
					<List />
				</div>
			</div>
    )
}

export default ExpertPicks