import React, { memo } from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

import Seo from '../../components/seo'

import HeroCarousel from '../../components/Hero/HeroCarousel'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'

import Sections from '../../components/Sections'

import Event from '../../components/Event'
import Promotion from '../../components/Blocks/CustomGulfstream/Promotion'

import MainFrameCover from '../../components/MainFrameCover'

import Modal from '../../components/Modal'
import LoginBox from '../../components/Blocks/LoginBox'
import SubscribeBox from '../../components/Blocks/SubscribeBox'
import IframeBox from '../../components/Blocks/IframeBox'
import TrackTrendsBox from '../../components/Blocks/TrackTrendsBox'
import Cookies from '../../components/Cookies'
// import { useAuth0 } from '../../utils/auth'
import CustomBox from '../../components/Blocks/CustomBox'

function Page(props) {
  const { data, pageContext } = props
  // const { loading } = useAuth0()

  // let SingleEvent = () => {
  // 	return <Event {...props.params} />
  // }

  // let SingleRace = () => {
  // 	return <Race {...props.params} />
  // }

  // let SinglePromotion = () => {
  // 	return <Promotion {...props.params} />
  // }

  // let SinglePage = () => {
  // 	return <Sections {...props} />
  // }

  // if (loading) {
  //   return <div>Loading...</div>
  // }

  return (
    <>

      <MainFrameCover />

      <Modal trigger={"#modal-1st-iframe"}>
        <IframeBox />
      </Modal>

      <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal>

      <Modal trigger={"#modal-1st-track-trends"}>
        <TrackTrendsBox />
      </Modal>

      <Modal trigger={"#modal-1st-custom-box"}>
        <CustomBox popup={data?.contentfulPage?.popups} />
      </Modal>

      <Modal trigger={"#modal-1st-general-custom-box"}>
        <CustomBox popup={data?.contentfulOptions?.popup} />
      </Modal>

      <Cookies />

      <main id="mainframe" className={`c0 layer active main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <Seo title={data.contentfulPage?.metaTitle} description={data.contentfulPage?.metaDescription} path={props.location?.pathname ? props.location?.pathname : ''} />

        {data.contentfulPage && pageContext.slug === "home" ?
          <>
            <div className="hero-and-sidebar">
              <HeroCarousel />
              <Sidebar />
            </div>
          </>
          : null}

        <Router primary={false}>
          <Event path="/event/entertainment/:id" {...props.params} />
          {/* <Race path="/event/racing/:id" {...props.params} /> */}
          <Promotion path="/promotion/:id" {...props.params} />
          <Sections path="/*" {...props} />
        </Router>

        <Footer />

      </main>

    </>
  )
}

export default memo(Page)

export const query = graphql`
  query($contentful_id: String!) {
    contentfulPage( contentful_id: { eq: $contentful_id } ) {
      id
      title
      slug
      metaTitle
      metaDescription
      sections {
        __typename
        id
        name
        classes
        anchor
        blocks {
          ...blockImage
          ...blockWysiwyg
          ...blockCustom
          ...blockTabs
          ...blockVideo
          ...blockWrapper
          ...blockButton
          ...blockDecor
          ...blockSlider
          ...blockToSection
          ...blockDirectInsert
        }
      }
      popups {
        id
        updatedAt
        title
        link
        formUrl
        formHeight
        content {
          raw
          references {
            ... on ContentfulAsset {
              __typename
              contentful_id
              title
              description
              gatsbyImageData(quality: 80, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
            }
            ...blockButton
            ...blockVideo
          }
        }
      }
    }
    contentfulOptions {
      popup {
        id
        updatedAt
        title
        link
        formUrl
        formHeight
        content {
          raw
          references {
            ... on ContentfulAsset {
              __typename
              contentful_id
              title
              description
              gatsbyImageData(quality: 80, layout: CONSTRAINED, breakpoints: [300, 500, 750, 1080, 1366, 1920], formats: WEBP)
            }
            ...blockButton
            ...blockVideo
          }
        }
      }
    }
  }
`
// Gatsby preffered way to load external scripts, placed in the <body> tag instead of <head>
export function Head() {
  return (
    <>
      {typeof window !== 'undefined' && typeof document !== 'undefined' ?
        <img src={`https://landingpage.experiture.com/PTrack.aspx?tkey=CE46AD46E9EB46CE9DAC005642C8BF0A&rfurl=` + encodeURIComponent(document.referrer) + `&pname=` + encodeURIComponent(document.location.href)} style={{ display: 'none' }} />
      : null}

      {/* <Script src="https://offer.fevo.com/js/fevo.js" />
      <Script src="//ndn.statistinamics.com/cstnxtm/9E0C2D97-97D9-4AA6-8B0F-E28BF9ED2986.js" async />
      <Script src="https://ten-palms.popmenu.com/s/pm.js" />
      <Script id="etmc-tracking">{
        `var _etmc = [];
          _etmc.push(["setOrgId", "534001827"]);
          _etmc.push(["trackPageView"]);`
      }
      </Script>

      {typeof window !== 'undefined' && window?.location?.pathname === "/driving-directions" ?
        <script id="schema-script" type="application/ld+json" >
          {`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Gulfstream Park Racing & Casino",
            "image": "",
            "@id": "",
            "url": "https://www.gulfstreampark.com",
            "telephone": "954-454-7000",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "901 S Federal Hwy",
              "addressLocality": "Hallandale Beach",
              "addressRegion": "FL",
              "postalCode": "33009",
              "addressCountry": "US"
            }  
          }`}
        </script>
        : null} */}
    </>
  )
}